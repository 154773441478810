<template>
  <div>
    <el-dialog
      :before-close="handleClose"
      @close="closeModal"
      destroy-on-close
      :title="title"
      :visible.sync="visible"
      width="55%"
    >
      <slot></slot>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["title", "visible"],
  name: "customModal",

  methods: {
    handleClose(done) {
      this.$confirm("Вы уверены что хотите закрыть окно?")
        .then(() => {
          done();
        })
        .catch(() => {});
    },

    closeModal() {
      this.$store.dispatch("setShowModal", false);
    },
  },
};
</script>

<style scoped></style>
