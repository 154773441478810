<template>
    <div>
      <tab-belniz></tab-belniz>
    </div>
  </template>
  
  <script>
  import tabBelniz from "@/components/admin/classifiers/tabBelniz";
  export default {
    name: "tabBelnizView",
    components: { tabBelniz },
  };
  </script>
  
  <style scoped></style>
  