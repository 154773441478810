<template>
  <div class="search">
    <h3 style="margin-top: 0; margin-bottom: 5px">Поиск {{ title }}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["title"],
  name: "searchBlock",
};
</script>

<style scoped>
.search {
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: #ecf5c7;
  padding: 10px;
}
</style>
