<template>
    <div>
        <el-form
            label-position="top"
            :model="waste"
            label-width="120px"
        >
            <el-form-item label="Код отхода">
                <el-input v-model="waste.ss01WasteDictionaryDTO.wasteCode"></el-input>
            </el-form-item>
            <el-form-item label="Имя объекта">
                <el-input v-model="waste.objectname"></el-input>
            </el-form-item>
            <el-form-item label="Адрес объекта">
                <el-input v-model="waste.objectaddress"></el-input>
            </el-form-item>
            <el-form-item label="Номер телефона объекта">
                <el-input v-model="waste.objectnumberPhone"></el-input>
            </el-form-item>
            <el-form-item label="Имя собственника">
                <el-input v-model="waste.ownername"></el-input>
            </el-form-item>
            <el-form-item label="Адрес собственника">
                <el-input v-model="waste.owneraddress"></el-input>
            </el-form-item>
            <el-form-item label="Номер телефона собственника">
                <el-input v-model="waste.ownernumberPhone"></el-input>
            </el-form-item>
            <el-form-item label="Использует собственные">
                <el-checkbox v-model="waste.own"></el-checkbox>
            </el-form-item>
            <el-form-item label="Принимает от других">
                <el-checkbox v-model="waste.alien"></el-checkbox>
            </el-form-item>
            <el-form-item label="Координаты широта">
                <el-input v-model="waste.lat"></el-input>
            </el-form-item>
            <el-form-item label="Координаты долгота">
                <el-input v-model="waste.lng"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="success" @click="submitForm(waste)">
                    {{waste.id == null ? "Добавить" : "Изменить"}}
                </el-button>
            </el-form-item>
        </el-form>
        
    </div>
</template>

<script>
import { AXIOS } from "@/AXIOS/http-common";
export default {
  props: ["action"],
  name: "editRowBelniz",
  data() {
    return {
        waste:{
                id:0,
                objectname:null,
                objectaddress:null,
                objectnumberPhone:null,
                ownername:null,
                owneraddress:null,
                ownernumberPhone:null,
                ss01WasteDictionaryDTO:{wasteCode:0},
                own:false,
                alien:false,
                lat:53.4250605,
                lng:27.6971358,
            }
        };
  },
  methods: {
    async submitForm(){
        await AXIOS.put("register-of-facilities/set", this.waste)
        this.$store.dispatch("setShowModal", null);
        await AXIOS.get("register-of-facilities/get?page="+this.waste.data.number+"&size="+this.waste.data.size).then((response) => {
                this.registerzero = response.data.content;
                this.dataAgreement.number=response.data.number
                this.dataAgreement.totalElements=response.data.totalElements
                this.dataAgreement.totalPages=response.data.totalPages
                this.dataAgreement.size=response.data.size
                this.dataAgreement.numberOfElements=response.data.numberOfElements
                console.log(this);
            });
    }
  },
  mounted() {
    if(this.action.id!=null)
    this.waste=this.action;
    console.log(this);
  },
}
</script>