<template>
    <div>
        <h1>tabBelniz</h1>
        <search-block>
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-select
                    clearable
                    class="searchPole"
                    v-model="search.code"
                    filterable
                    remote
                    value-key="name"
                    reserve-keyword
                    placeholder="Код отхода"
                    :remote-method="searchCode"
                    >
                        <el-option
                            v-for="item in ss01CodeList"
                            :key="item.name"
                            :label="item.name"
                            :value="item.name"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                        <el-input v-model="search.name" ></el-input>
                </el-col>
                <el-col :span="6"><el-button @click="searchRegisterOfFacilitis(search)" type="primary"
                    >Поиск</el-button></el-col>
                <el-col :span="6">
                    <el-button @click="editRregister(reg)" type="primary"
                    >Добавить</el-button>
                </el-col>
            </el-row>
        </search-block>
        <el-table stripe :data="registerzero">
            <el-table-column label="id" prop="id"></el-table-column>
            <el-table-column label="Наименование отхода" prop="ss01WasteDictionaryDTO.wasteCode"></el-table-column>
            <el-table-column label="objectname" prop="objectname"></el-table-column>
            <el-table-column label="objectaddress" prop="objectaddress"></el-table-column>
            <el-table-column label="objectnumberPhone" prop="objectnumberPhone"></el-table-column>
            <el-table-column label="ownername" prop="ownername"></el-table-column>
            <el-table-column label="owneraddress" prop="owneraddress"></el-table-column>
            <el-table-column label="ownernumberPhone" prop="ownernumberPhone"></el-table-column>
            <el-table-column label="raw" prop="">
                <template slot-scope="raw">
                    {{ raw.row.own == true ? "да": "нет"}}
                </template>
            </el-table-column>
            <el-table-column label="alien" prop="">
                <template slot-scope="raw">
                    {{ raw.row.alien == true ? "да": "нет"}}
                </template>
            </el-table-column>
            <el-table-column label="lat" prop="lat"></el-table-column>
            <el-table-column label="lng" prop="lng"></el-table-column>
            <el-table-column label="" prop="">
                <template slot-scope="raw">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-button
                                size="mini"
                                style="float: right"
                                @click="editRregister(raw.row)"
                                type="warning"
                                >Редактировать</el-button>
                            <el-button
                                size="mini"
                                style="float: right"
                                @click="deleteRregister(raw.row)"
                                type="danger"
                                >Удалить</el-button>
                        </el-col>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: center"
            layout="total, sizes, prev, pager, next"
            background
            :page-sizes="[50,100,200,500,1000]"
            :page-size="dataAgreement.size"
            :current-page.sync="dataAgreement.number + 1"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="dataAgreement.totalElements"
            >
        </el-pagination>
        <custom-modal
            :title="'Реестр белниц'"
            :visible="showModal === 'editRregister'"
            v-if="showModal === 'editRregister'"
            >
            <edit-row-belniz :action="action"></edit-row-belniz>
        </custom-modal>
    </div>
</template>
<script>

import { AXIOS } from "@/AXIOS/http-common";
import CustomModal from "@/components/customModal";
import SearchBlock from "@/components/searchBlock";
import editRowBelniz from "@/components/admin/classifiers/editRowBelniz";
import { mapGetters } from "vuex";

export default{
    components: {
    CustomModal,
    editRowBelniz,
    SearchBlock
    },
    data() {
        return{
            search: {
                code: 0,
                name: "",
                alien: null,
                own: null,
                page:0,
                size:100,
                ser:false
            },
            dataAgreement:{
                number:0,
                numberOfElements:0,
                size:100,
                totalElements:0,
                totalPages:0
            },
            action:null,
            registerzero:[{
                id:null,
                objectname:null,
                objectaddress:null,
                objectnumberPhone:null,
                ownername:null,
                owneraddress:null,
                ownernumberPhone:null,
                ss01WasteDictionaryDTO:null,
                own:null,
                alien:null,
                lat:null,
                lng:null,
            }],
            reg:{
                id:null,
                objectname:null,
                objectaddress:null,
                objectnumberPhone:null,
                ownername:null,
                owneraddress:null,
                ownernumberPhone:null,
                ss01WasteDictionaryDTO:null,
                own:null,
                alien:null,
                lat:null,
                lng:null,
            }
        };
    },
    computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
      ss01CodeList: "GETSSS01CODELIST",
    }),
    },
    methods: {
        async searchCode(query) {
            await this.$store.dispatch("getCodeList", query);
        },
        editRregister(raw) {
                this.action=raw;
                this.action.data=this.dataAgreement;
                this.$store.dispatch("setShowModal", "editRregister");
            },

        async deleteput(raw)
        {
            await AXIOS.put("register-of-facilities/delete", raw);
            if (this.search.ser == true)
            this.searchRegisterOfFacilitis({ page: this.dataAgreement.number, size: this.dataAgreement.numberOfElements ,code:this.search.code,name:this.search.name});
            else
            this.getRegisterOfFacilitis({ page: this.dataAgreement.number, size: this.dataAgreement.numberOfElements });
        },
        deleteRregister(raw){
             this.confirm(
                "Удалить данные?",
                "Вы уверены что хотите удалить данные",
                "warning",
                "Удалить",
                "Отмена"
            ).then(() => {
            this.deleteput(raw)
            });
        },
        
        async getRegisterOfFacilitis(data) {
            await AXIOS.get("register-of-facilities/get?page="+data.page+"&size="+this.dataAgreement.size).then((response) => {
                this.registerzero = response.data.content;
                this.dataAgreement.number=response.data.number
                this.dataAgreement.totalElements=response.data.totalElements
                this.dataAgreement.totalPages=response.data.totalPages
                //this.dataAgreement.size=response.data.size
                this.dataAgreement.numberOfElements=response.data.numberOfElements
                console.log(this);
            });
        },

        async searchRegisterOfFacilitis(data) {
            this.search.ser=true;
            await AXIOS.get("register-of-facilities/search?page="+data.page+"&size="+this.dataAgreement.size+"&name="+data.name+"&code="+data.code).then((response) => {
                this.registerzero = response.data.content;
                this.dataAgreement.number=response.data.number
                this.dataAgreement.totalElements=response.data.totalElements
                this.dataAgreement.totalPages=response.data.totalPages
                //this.dataAgreement.size=response.data.size
                this.dataAgreement.numberOfElements=response.data.numberOfElements
                console.log(this);
            });
        },

        handleCurrentChange(val) {
            if (this.search.ser == true)
            this.searchRegisterOfFacilitis({ page: val - 1,code:this.search.code,name:this.search.name});
            else
            this.getRegisterOfFacilitis({ page: val - 1});
        },

        handleSizeChange() {
            if (this.search.ser == true)
            this.searchRegisterOfFacilitis({ page: 0, code:this.search.code,name:this.search.name });
            else
            this.getRegisterOfFacilitis({ page: 0});
        },
    },
    async mounted() {
        await this.getRegisterOfFacilitis({page:0});
    },
    
};
</script>
